import React from 'react';
import formatDateTime from "../../helper/date"

const RcDateTimer = ( { date } ) => {
  if (!date) {
    return null;
  }

  return <>{ formatDateTime(date) }</>;
}

export default RcDateTimer;
