import React from 'react';
import * as styles from './rcEventSubcaption.module.scss';
import RcDateTime from "../helper/rcDateTime"

import LocationSVG from "../../icons/fontawesome/light/location-circle.svg";
import TimeSVG from "../../icons/fontawesome/light/business-time.svg";
import UsersSVG from "../../icons/fontawesome/light/users.svg";
import RcDateTimeUntil from "../helper/rcDateTimeUntil"

const RcEventSubcaption = ( { event, className = '', style = "normal" } ) => {

  let date = event.acf_elements.date;

  if (typeof date === "string") {
    date = new Date(date * 1000);
  }

  let flexCSS = "flex-column flex-lg-row ";

  if (style === "small") {
    flexCSS = "flex-row ";
  }

  return <>
    <div className={"d-flex w-100 justify-content-between mb-2 " + flexCSS + " " + styles.subcaption + " " + className + " " + styles[style]}>
      <div className={"d-flex align-items-center"}>
        {event.acf_elements?.location &&
        <>
          <LocationSVG className={"mr-2 svgFill"}/>
          {event.acf_elements.location}
        </>
        }
      </div>

      <div className={"d-flex align-items-center "  + styles.subcaption}>
        {event.acf_elements?.date &&
        <>
          <TimeSVG className={"mr-2 svgFill"}/>
          <RcDateTime date={date}/>
          <RcDateTimeUntil until={event.acf_elements?.dateuntil} />
        </>
        }
      </div>

    </div>

    <div className={"mb-2 d-flex align-items-center " + styles.subcaption }>
      {event.acf_elements?.speakers &&
      <>
        <UsersSVG className={"mr-2 svgFill"} />
        { event.acf_elements.speakers }
      </>
      }
    </div>

  </>
};

export default RcEventSubcaption;
