import React from 'react';
import Layout from "../page-components/layout"
import * as  styles from './events.module.scss';

import { graphql } from "gatsby"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"
import RcCard from "../components/card/rcCard"
import RcCardFull from "../components/card/rcCardFull"
import RcEventSubcaption from "../page-components/event/rcEventSubcaption"

const EventsPage = ( { data }) => {

  const nodes = data.allWpEvent.nodes.map(
    n => {
      return {
        ...n,
        acf_elements: {
          ...n.acf_elements,
          date: new Date(Number.parseFloat(n.acf_elements.date) * 1000)
        }
      }
    }
  );

  const now = new Date().getTime();

  const pastEvents = nodes.filter( n => n.acf_elements.date.getTime() <= now );
  const upcomingEvents = nodes.filter( n => n.acf_elements.date.getTime() >= now );

  return (
    <Layout>

      <RcDefaultPageTeaser>
        <h1>Events</h1>

        <div dangerouslySetInnerHTML={
          {
            __html: data.wp.texteFRSektionen.acf.introevents
          }
        } />

      </RcDefaultPageTeaser>

      { upcomingEvents?.length > 0 &&
      <div className="container">
          <div className="row h-100 bg-white pt-4 pb-2">

            { upcomingEvents.map(e => {

                if (e.acf_elements.hidden) {
                  return null;
                }

                return <div className={"col-12"}>
                    <RcCardFull fluid={e.acf_elements.header?.localFile.childImageSharp.gatsbyImageData}
                                className={"mb-5"}
                                containImage={true}
                                to={"/events/" + e.slug}
                                actionText={"Details & Anmeldung"}
                                subCaption={<RcEventSubcaption event={e} />}
                                caption={e.title}>
                        {e.acf_elements.description}
                      </RcCardFull>
                </div>
              }
            ) }

          </div>
        </div>
        }


        {pastEvents?.length > 0 && <div className="container pb-3 bg-white">
            <div className="row bg-white pt-3">

                <div className="col-12">
                  <h3 className={styles.pastEventsCaption + " mb-2 w-100"}>Vergangene Events</h3>
                </div>

                  {/*RcCards have their own col-x definings*/}
                  { pastEvents.map(
                    n => {

                      if (n.acf_elements.hidden) return null;

                      return <RcCard key={n.id}
                              className={"mb-4"}
                              innerClassName={"h-100"}
                              to={"/events/" + n.slug}
                              caption={n.title}
                              containImage={true}
                              isPremium={n.acf_elements?.price > 0}
                              children={ <RcEventSubcaption style={"small"} event={n} className={"flex-column"} /> }
                              img={n.acf_elements?.header?.localFile.childImageSharp?.gatsbyImageData} />
                    }
                  )}

              </div>
          </div>
        }

    </Layout>
  );

};

export default EventsPage;


export const query = graphql`
  query WpEventList {
    wp {
      texteFRSektionen {
          acf {
            introevents
          }
      }
    }
  
    allWpEvent(sort: {fields: acf_elements___date, order: DESC}) {
      nodes {
          id
          slug
          title
          content
          acf_elements {
            header {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            typ
            date
            dateuntil
            speakers
            location
            description
            price
            hidden
          }
        }
      }
    }
`
