import React from 'react';
import { formatTime } from "../../helper/date"

const RcDateTimeUntil = ({until}) => {

  if (!until) {
    return null;
  }

  return <> bis { formatTime(until) }</>;

};

export default RcDateTimeUntil;
