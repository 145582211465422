const twoDigits = (number) => {
  if (number <= 9) {
    return "0" + number;
  }

  return number;
}

const formatDateTime = (date) => {
  if (!date) return "";
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} um ${twoDigits(date.getUTCHours())}:${twoDigits(date.getMinutes())}`
};

const formatTime = (date) => {
  const splitted = date.split(" ");
  const time = splitted[1];
  return time;
}


const formatDate = (date) => {
  if (!date) return "";
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

export default formatDateTime;
export { formatDate, formatTime };
